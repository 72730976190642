import React from 'react'
import { Button as ButtonBS } from 'react-bootstrap'
import { TbBrandGithub } from 'react-icons/tb'
import { useDarkMode } from '../../context/DarkModeContext'
import { useTranslations } from '../../context/TranslationsContext'
import { Image } from 'react-bootstrap'
import { Badge } from '../Atoms'
import { Damage } from '../../assets/icons'

export const Button = ({ children, className = '', style, variant = 'primary', active, ...props }) => {
    const { isDark } = useDarkMode()
    const { t } = useTranslations();
    return (
        <ButtonBS
            variant={
                variant.includes('outline-primary')
                    ? isDark ? 'outline-secondary' : 'outline-primary'
                    : variant.includes('primary')
                        ? isDark ? 'secondary' : 'primary'
                        : variant
            }
            className={`d-inline-flex align-items-center ${variant === 'link' ? isDark ? 'text-light' : 'text-dark' : ''} ${className} `}
            style={{
                gap: '.25rem',
                transform: `scale(${active ? .9 : 1})`,
                transition: '.2s',
                ...style
            }}
            active={active}
            {...props}
        >
            {t(children)}
        </ButtonBS>
    )
}

export const GithubButton = ({link}) => {
    return (
        <Button size="sm" style={{ backgroundColor: "#e2e2e2", color: "black", borderColor: "#333333" }} as="a" href={"https://git.cacia.dev/cacia/pokemmo-hub"} target="_blank"><TbBrandGithub />Github</Button>
    )
}

export const PokeMMOButton = ({link}) => {
    return (
        <Button size="sm" style={{ backgroundColor: "#ffcb05", color: "black", borderColor: "#ffcb05" }} as="a" href={link} target="_blank">PokeMMO</Button>
    )
}

export const DamageCalcButton = () => {
    const { t } = useTranslations()
    const label = "Damage Calculator"
    const url = "https://calc.pokemonshowdown.com/"
    return (
        <Button variant={null ? 'info' : 'primary'} key={label} as="a" className="d-inline-flex align-items-center" href={url} target="_blank">
            <Image style={{ height: '1.5em', width: '1.5em' }} src={Damage} alt={'Damage'}/>
            {t(label)}
            {null ? <Badge className="ms-1" style={{ fontSize: '.6rem', color: 'black' }} bg="warning" pill>NEW</Badge> : false}
        </Button>
    )
}

export const EVHordesButton = () => {
    const { t } = useTranslations()
    const label = "EV Hordes"
    const url = "https://forums.pokemmo.com/index.php?/topic/108705-2024-all-horde-locations-ev-and-shiny-johto-updated/"
    const icon = "https://forums.pokemmo.com/uploads/monthly_2022_03/out.ico"
    return (
        <Button variant={null ? 'info' : 'primary'} key={label} as="a" className="d-inline-flex align-items-center" href={url} target="_blank">
            <Image style={{ height: '1.5em', width: '1.5em' }} src={icon} />
            {t(label)}
            {null ? <Badge className="ms-1" style={{ fontSize: '.6rem', color: 'black' }} bg="warning" pill>NEW</Badge> : false}
        </Button>
    )
}

export const TeamPlannerButton = () => {
    const { t } = useTranslations()
    const label = "Team Planner"
    const url = "https://richi3f.github.io/pokemon-team-planner/"
    const icon = "https://richi3f.github.io/pokemon-team-planner/static/favicon.ico"
    return (
        <Button variant={null ? 'info' : 'primary'} key={label} as="a" className="d-inline-flex align-items-center" href={url} target="_blank">
            <Image style={{ height: '1.5em', width: '1.5em' }} src={icon} />
            {t(label)}
            {null ? <Badge className="ms-1" style={{ fontSize: '.6rem', color: 'black' }} bg="warning" pill>NEW</Badge> : false}
        </Button>
    )
}