import React from 'react';
import { useDarkMode } from '../../context/DarkModeContext';
import { useTranslations } from '../../context/TranslationsContext';
import { Form } from 'react-bootstrap'

export const TextField = ({ label, translate = true, textClassName, ...props }) => {
    const { isDark } = useDarkMode();
    const { t } = useTranslations();

    return (
        <Form.Group className='custom-textfield'>
            <Form.Text className={textClassName ? textClassName : null}>
                {translate ? t(label) : label}
            </Form.Text>
            <Form.Control
                {...props}
                variant="outlined"
                placeholder={translate ? t(label) : label}
                className={`${isDark ? 'textfield-dark' : 'textfield-light'}`}
            />
        </Form.Group>
    );
};